import Vue from 'vue'
import VueRouter from 'vue-router'
















Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Unk',
		redirect: '/sign-in',
	},
	{
		path: '/api-docs',
		name: 'ApiDocs',
		component: () => import('../views/Docs.vue'),
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
	},
	{
		path: '/layout',
		name: 'Layout',
		layout: "dashboard",
		component: () => import('../views/Layout.vue'),
	},
	 
	{
		path: '/virtualaccounts',
		name: 'VirtualAccounts',
		layout: "dashboard",
		component: () => import('../views/VirtualAccounts.vue'),
	}, 
	{
		path: '/facebookaccounts',
		name: 'FbAccounts',
		layout: "dashboard",
		component: () => import('../views/FacebookAccounts.vue'),
	},
	{
		path: '/facebookaccounts/:id/facebookpages',
		name: 'FbPages',
		layout: "dashboard",
		component: () => import('../views/FacebookPages.vue'),
	},
	{
		path: '/facebookaccounts/:id/facebookpages/:pageid/instagrampages',
		name: 'InstagramPages',
		layout: "dashboard",
		component: () => import('../views/InstagramPages.vue'),
	},
	{
		path: '/tiktokaccounts',
		name: 'TiktokAccounts',
		layout: "dashboard",
		component: () => import('../views/TikTokAccounts.vue'),
	},
	{
		path: '/tiktokaccounts/:id',
		name: 'TikToks',
		layout: "dashboard",
		component: () => import('../views/ComingSoon.vue'),
	},
	{
		path: '/twitteraccounts',
		name: 'TwitterAccounts',
		layout: "dashboard",
		component: () => import('../views/ComingSoon.vue'),
	},

	{
		path: '/multiposts',
		name: 'Multiposts',
		layout: "dashboard",
		component: () => import('../views/ComingSoon.vue'),
	},

	{
		path: '/images',
		name: 'Images',
		layout: "dashboard",
		component: () => import('../views/ComingSoon.vue'),
	},

	{
		path: '/videos',
		name: 'Videos',
		layout: "dashboard",
		component: () => import('../views/ComingSoon.vue'),
	},

	{
		path: '/docs',
		name: 'Docs',
		layout: "dashboard",
		component: () => import('../views/Docs.vue'),
	},
	{
		path: '/billing',
		name: 'Billing',
		layout: "dashboard",
		component: () => import('../views/Billing.vue'),
	},
	{
		path: '/rtl',
		name: 'RTL',
		layout: "dashboard-rtl",
		meta: {
			layoutClass: 'dashboard-rtl',
		},
		component: () => import('../views/RTL.vue'),
	},
	{
		path: '/Profile',
		name: 'Profile',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
		},
		component: () => import('../views/Profile.vue'),
	},
	{
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Sign-In.vue'),
	},
	{
		path: '/sign-up',
		name: 'Sign-Up',
		meta: {
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/Sign-Up.vue'),
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

export default router

import socketIOClient from "socket.io-client";

import { APIBASE_HOST, SOCKET_HOST } from "../../../env";
import {currentToken, isUserLoggedIn, clearUserInfo} from "../v1/data_methods";


export async function getDocText() {

    let addr = window.location.hostname;

    let response = null;

    if('localhost' === window.location.hostname) {
        console.log('localhost');

        response = await fetch(`http://${addr}:9788/doc.txt`);
    }
    else {
        response = await fetch(`https://${addr}/doc.txt`);
    }

    // const response = await fetch(`http://${addr}:5000/`);
    const data = await response.text();
    return data;
}




export function getSocket() {

    const token = currentToken();

    const socket = socketIOClient(SOCKET_HOST, {
        query: {
            token: token
        }
    });

    return socket;
}
  
export async function fetchDataJSON(path, useAuth = false, store=null) {

    let token = null;

    if(store !== null) {

        token = store.getters.getCurrentToken;
    }

    else{
        token = currentToken();
    }

    if (useAuth && token === null) {
        return null;
    }

    const url = APIBASE_HOST + path;

    const headers = {
        'Accept': 'application/json',
    };

    
    if (useAuth) {

        headers['Authorization'] = 'Bearer ' + token;
    }

    const response = await fetch(url, {headers});

    if (!response.ok) {

        const stat = await response.text()

        if(stat.indexOf("Could not validate credentials") > -1) {

            clearUserInfo();

            window.location.href = "/login";

        }

        const result = {
            status: stat,
            data: null,
            Ok: false,
        }

        return result
    }

    const result = {
        status: 'Ok',
        data: await response.json(),
        Ok: true,
    }

    return result;
  }

  export const uploadFile = async (path, data, params, useAuth = true, store=null) => {


    let token = null;

    if(store !== null) {

        token = store.getters.getCurrentToken;

    }

    else{
        token = currentToken();
    }
    
    
        if (useAuth && token === null) {
            return null;
        }
    
        let url = APIBASE_HOST + path;

        if(params) {
            let paramsStr = '';
            for (let key in params) {
                if (paramsStr !== '') {
                    paramsStr += '&';
                }
                paramsStr += key + '=' + params[key];
            }
            url += '?' + paramsStr;
        }
    
        const headers = {
            'Accept': 'application/json',
        };
    
        if (useAuth) {
            headers['Authorization'] = 'Bearer ' + token;
        }
    
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: data
        });
    
        if (!response.ok) {
    
            const stat = await response.text();
    
            if(stat.indexOf("Could not validate credentials") > -1) {
    
                clearUserInfo();
    
                window.location.href = "/login";
    
            }
    
            const result = {
                status: stat,
                data: null,
                Ok: false,
            }
    
            return result
        }
    
        const result = {
            status: 'Ok',
            data: await response.json(),
            Ok: true,
        }
    
        return result;
    }

  export const fetchPostJSON = async (path, data, useAuth = true, store=null) => {

    console.log('fetchPostJSON:', path);


    let token = null;

    if(store !== null) {

        console.log('store:', store);

        token = store.getters.getCurrentToken;

    }

    else{
        token = currentToken();
    }

    

    if (useAuth && token === null) {
        return null;
    }

    const url = APIBASE_HOST + path;

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if (useAuth) {
        headers['Authorization'] = 'Bearer ' + token;
    }

    console.log('url:', url);

    const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    });

    console.log('response:', response);

    if (!response.ok) {
        const stat = await response.text();

        if(stat.indexOf("Could not validate credentials") > -1) {

            clearUserInfo();

            window.location.href = "/login";

        }

        const result = {
            status: stat,
            data: null,
            Ok: false,
        }

        return result
    }

    const result = {
        status: 'Ok',
        data: await response.json(),
        Ok: true,
    }

    return result;
}

export const userLoginJSON = async (data) => {

    const path = 'users/token-auth';

    const url = APIBASE_HOST + path;

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        });

        if (!response.ok) {

            const result = {
                status: await response.json(),
                data: null,
                Ok: false,
            }

            return result
        }

        const result = {
            status: 'OK',
            data: await response.json(),
            Ok: true,
        }

        return result;
    } catch (e) {
        console.log(e);

        return {
            status: {
                detail: 'Network error, please check your internet connection and try again: ' + e
            },
            data: null,
            Ok: false,
        }
    }
}

export const userSignupJSON = async (data, params) => {

    const path = 'users/user-signup';

    let url = APIBASE_HOST + path;

    let paramsStr = '';

    for (let key in params) {
        if (paramsStr !== '') {
            paramsStr += '&';
        }
        paramsStr += key + '=' + params[key];
    }

    if(params) {
        url += '?' + paramsStr;
    }

    console.log('url:', url);

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
    

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        });

        if (!response.ok) {

            const result = {
                status: await response.text(),
                data: null,
                Ok: false,
            }

            return result
        }

        const result = {
            status: 'OK',
            data: await response.json(),
            Ok: true,
        }

        return result;
    }
    catch (e) {
        console.log(e);

        return {
            status: {
                detail: 'Network error, please check your internet connection and try again: ' + e
            },
            data: null,
            Ok: false,
        }
    }
}
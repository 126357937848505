<template>

	<!-- Settings Drawer -->
	<a-drawer
		class="settings-drawer"
		:class="[ rtl ? 'settings-drawer-rtl' : '' ]"
		:placement="rtl ? 'left' : 'right'"
		:closable="false"
		:visible="showSettingsDrawer"
		width="360"
		:getContainer="() => wrapper"
		@close="$emit('toggleSettingsDrawer', false)"
	> 

		<!-- Settings Drawer Close Button -->
		<a-button type="link" class="btn-close" @click="$emit('toggleSettingsDrawer', false)">
			<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9">
				<g id="close" transform="translate(0.75 0.75)">
					<path id="Path" d="M7.5,0,0,7.5" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
					<path id="Path-2" data-name="Path" d="M0,0,7.5,7.5" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
				</g>
			</svg>
		</a-button>
		<!-- / Settings Drawer Close Button -->
		
		<!-- Settings Drawer Content -->
		<div class="drawer-content">
			<h6>Configurator</h6>
			<p>Configure your global dashboard options.</p>

			<span v-if="current_user_info"><b>Account:</b> {{ current_user_info.username }}</span>

			

			<vue-loaders-ball-beat v-if="processing" color="blue" scale="1"></vue-loaders-ball-beat>
			
			<hr>

			<div v-if="$store.getters.getUserAccountList.length==0">
				<h6>No Virtual Accounts</h6>
				<p>You have no Accounts. <a href="#/virtualaccounts">Create</a> a virtual account in order to start.</p>
			</div>
			
			<div class="sidenav-type" v-if="$store.getters.getUserAccountList.length>0">
				<h6>Current Virtual Account</h6>
				<p>Choose an active account in order to start.</p>
				
				
            	<b-form-select
              		id="input-xw2"
					class="select-long"
              		v-model="current_account_id"
              		value-field="id"
              		text-field="name"
              		:options="$store.getters.getUserAccountList"
              		required
					@change="currentAccountIdChanged"
            	></b-form-select>
        
			</div>

			<br><br>

			<div>
				<h6>Log Out</h6>
				<p>Log out of your current account.</p>
				
				
            	<a-button type="primary" @click="doLogout" block>
					Log Out
				</a-button>
        
			</div>

			<br><br>
			
			
			
		</div>
		<!-- / Settings Drawer Content -->

	</a-drawer>
	<!-- / Settings Drawer -->

</template>

<script>
	import {fetchDataJSON, fetchPostJSON} from '../../services/v1/services';

	import {getCurrentUserAccountId, saveCurrentUserAccountId, saveCurrentUseraccountRole, getCurrentUseraccountId, getCurrentUseraccountRole, getUserInfo, clearUserInfo, isUserLoggedIn} from '../../services/v1/data_methods';

	import 'vue-github-buttons/dist/vue-github-buttons.css'; // Stylesheet
	import VueGitHubButtons from 'vue-github-buttons';
	import Vue from 'vue';
	Vue.use(VueGitHubButtons, { useCache: true });

	export default ({
		props: {
			// Settings drawer visiblility status.
			showSettingsDrawer: {
				type: Boolean,
				default: false,
			},
			
			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary",
			},
			
			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "light",
			},

			// Header fixed status.
			navbarFixed: {
				type: Boolean,
				default: false,
			},

			// Drawer direction.
			rtl: {
				type: Boolean,
				default: false,
			},
		},
		updated: async function(){
			
			console.log('DashboardSettingsDrawer updated.');

			if(!this.$store.getters.isUserLoggedIn){
				console.log('user not logged in, skipping drawer check');
				return;
			}
		},
		// on loaded

		data() {
			return {
				// The wrapper element to attach dropdowns to.
				wrapper: document.body,
				
				// Main sidebar color.
				sidebarColorModel: this.sidebarColor,
				
				// Main sidebar theme : light, white, dark.
				sidebarThemeModel: this.sidebarTheme,

				// Header fixed status.
				navbarFixedModel: this.navbarFixed,

				lastaccountsFetchTime: null,

				accounts: [],

				current_account_id: null,

				current_user_info: null,

				preferences: {},

				processing: false,

				periodic1: null,
			}
		},
		mounted: async function(){
			// Set the wrapper to the proper element, layout wrapper.
			this.wrapper = document.getElementById('layout-dashboard') ;

			console.log('DashboardSettingsDrawer mounted.');

			if(!this.$store.getters.isUserLoggedIn){
				console.log('user not logged in, skipping mounted proc');
				return;
			}


			this.accounts = this.$store.getters.getUserAccountList;

			this.preferences = this.$store.getters.getPreferences;

			this.current_account_id = this.preferences.current_virtual_account;

			this.$store.dispatch('saveAccountId', this.current_account_id);


			this.$eventHub.$on("currentAccountChanged", async (item) => {
				console.log('currentAccountUpdated evt', item);

				// const currentUserInfo = getUserInfo();

				// this.preferences.current_account_id = item.value;

				// save the current account id and role to local storage

				// saveCurrentUserAccountId(currentUserInfo.id, item.value);

				// await fetchPostJSON('users/profiles/preferences/update', item, true);
			});


			this.$eventHub.$on("loadedPreferences", async (item) => {
				console.log('loadedPreferences loaded evt', item);

				// this.$store.dispatch('loadAccountList', true);

				// this.accounts = this.$store.getters.getUserAccountList;

				this.preferences = this.$store.getters.getPreferences;

				this.current_account_id = this.preferences.current_virtual_account;

				this.$store.dispatch('saveAccountId', this.current_account_id);
			});

			this.$eventHub.$on("updateAccountsList", async (item) => {
				console.log('currentAccountlist updated evt', item);

				this.$store.dispatch('loadAccountList', true);
			});

		},

		beforedestroyed: function(){
			this.$eventHub.$off("currentAccountUpdated");

			this.$eventHub.$off("loadedPreferences");

			if(this.periodic1){
				clearInterval(this.periodic1);
			}
		},

		methods: {
			doLogout: function(){
				clearUserInfo();
				this.$store.dispatch('clearData');
				this.$eventHub.$emit("userLoggedOut", {time: Date.now()});
				this.$router.push('/sign-in');
			},
			currentAccountIdChanged: async function(e){

				// get the current account item from list using id

				this.processing = true;

				const newID =e;

				const currentaccountItem = this.accounts.find(account => account.id == newID);

				console.log('currentaccountItem', currentaccountItem);

				// update user preferences

				const item = {
					value: newID,
					name: 'current_account_id'
				};


				

				let newPreferences = this.preferences;

				newPreferences.current_virtual_account = newID;

				const preferencesRes = await fetchPostJSON('users/preferences', newPreferences, true, this.$store);

				this.processing = false;

				if(preferencesRes.Ok){
					console.log('updated preferencesRes', preferencesRes);

					this.current_account_id = newID;

					this.$store.dispatch('saveAccountId', this.current_account_id);

					this.$eventHub.$emit("currentAccountChanged", item);
				}

				else{
					console.log('error preferencesRes', preferencesRes);

					this.$message.error('Error updating preferences');

					// reload window

					window.location.reload();
				}

				

			},
		},
	})

</script>

<style scoped>

.select-long{
	width: 100%;

	height: 40px;
}
</style>

/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import BootstrapVue from 'bootstrap-vue';
import SweetModal from 'sweet-modal-vue/src/plugin.js'
import Vue from 'vue'
import VueLoaders from 'vue-loaders';
import 'vue-loaders/dist/vue-loaders.css';
import Vuex from 'vuex';
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'







// import {BASE_URL} from '../env';
import App from './App.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'
import DefaultLayout from './layouts/Default.vue'

import { fetchDataJSON, fetchPostJSON } from './services/v1/services'; 

import router from './router'
// import './plugins/click-away'

import './scss/app.scss';


import VueApexCharts from 'vue-apexcharts'







Vue.use(Antd);

Vue.use(Vuex);

Vue.use(VueLoaders);
Vue.use(SweetModal);
Vue.use(BootstrapVue);

Vue.use(VueTour)

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false


function getItem(key) {
  const existing = sessionStorage.getItem(key) || null
  try {
    const value = JSON.parse(existing);
    return value;
  } catch (e) {
    // // outdisp('err data:' + e);
    return existing;
  }
}

function saveItem(key, val) {
  if (typeof val === 'string') {
    sessionStorage[key] = val
  }
  else{
    sessionStorage[key] = JSON.stringify(val)
  }
}

function deleteItem(key) {

  sessionStorage.removeItem(key);
}


const store = new Vuex.Store({
  state: {
      currentToken: null,
      currentSid: null,
      currentUserAccountID: null,
      accountList:[],
      currentUserInfo: null,
      accountListLoading: false,
      prefrences: {
        current_virtual_account: null,
      },

  },
  mutations: {
      clearToken (state) {

        deleteItem('tok');

        deleteItem('usn');

        deleteItem('uid');

        deleteItem('sid');

        state.currentToken = null;
        state.currentUserInfo = null;
        state.currentSid = null;
        state.currentUserAccountID = null;
        state.accountList = [];
        state.accountListLoading = false;
        state.prefrences = {
          current_virtual_account: null,
        };


      },
      setCurrentSid (state, sid) {
        console.log(sid)
        state.currentSid = sid;
      },
      setcurrentToken (state, token) {
          state.currentToken = token;
          saveItem('tok', state.currentToken)
      },
      setCurrentUserAccountID (state, userid, accountid) {

        state.currentUserAccountID = accountid;

        const saveKey = 'aid_' + userid;

        saveItem(saveKey, state.currentUserAccountID)
      },
      setCurrentUserInfo (state, info) {
        console.log(info)
        state.currentUserInfo = info;
        saveItem('userInfo', info)

      },

  },
  actions: {
      "customEmit" () {
          // do something
          console.log('customedit')
      },
      clearData (context) {
        // state.currentToken = null;
        context.commit('clearToken');

        deleteItem('tok');

      },
      
      clearLocalToken (context) {
        deleteItem('tok');
      },
      saveUserInfo (context, userInfo) {
        context.commit('setCurrentUserInfo', userInfo);
      },
      saveUserToken (context, token) {
        context.commit('setcurrentToken', token);
      },

      saveAccountId (context, accountid) {
        console.log('saving account id: ' + accountid);
        console.log('saving user id: ' + context.getters.getCurrentUserInfo.id);
        context.commit('setCurrentUserAccountID', context.getters.getCurrentUserInfo.id, accountid);
      },
 
      async loadPreferences (context) {

        const isLoggedIn = context.getters.isUserLoggedIn;

        if(!isLoggedIn) {

          // console.log('not logged in, not loading preferences');

          return;
        }

        const currentUserInfo = context.getters.getCurrentUserInfo;

        if(currentUserInfo === null) {

          console.log('no user info, not loading preferences');

          return;

        }

        const userid = currentUserInfo.id;

        if(userid === null) {

          console.log('no userid, not loading preferences');

          return;

        }

        const preferencesRes = await fetchDataJSON('users/preferences', true, context);

        if(preferencesRes.Ok) {

          context.state.prefrences = preferencesRes.data.preferences;

          console.log('preferences loaded');
 
          Vue.prototype.$eventHub.$emit('loadedPreferences', preferencesRes.data.preferences);

        }

      },
      async loadAccountList (context) {

        const isLoggedIn = context.getters.isUserLoggedIn;

        if(!isLoggedIn) {
          console.log('not logged in, not loading account list');
          return;
        }

        context.state.accountListLoading = true;

        const currentUserInfo = context.getters.getCurrentUserInfo;


        if(currentUserInfo === null) {
          console.log('no user info, not loading account list');
          return;
        }


        const userid = currentUserInfo.id;

        console.log('userid: ' + userid);

        if(userid === null) {
          console.log('no userid, not loading account list');
          return;
        }

        const accountRes = await fetchDataJSON('users/accounts/list', true, context);

        if(accountRes.Ok) {

          context.state.accountList = accountRes.data.accounts;

          // console.log('account list loaded');

        }

        context.state.accountListLoading = false;
        
      } 
  },
  getters: {
    // Check if user is logged in
    isUserLoggedIn: state => {

      if (state.currentToken !== null) {

        return true;
      }

      if (getItem('tok')) {
       return getItem('tok')!==null;
      }
    },

    getCurrentToken: state => {

      if (state.currentToken) {

        return state.currentToken
      }

      if (getItem('tok')) {
        
        const token = getItem('tok');
        
        state.currentToken = token;

        return token;
      }
    },
    getCurrentUsername: state => {

      if (getItem('usn')) {
        return getItem('usn');
      }

      return state.currentUserInfo.username;
    },
    getCurrentSid: state => {

      return state.currentSid;
    },
    getCurrentUserId: state => {

      if(state.currentUserId) {
        return state.currentUserInfo.id;
      }

      if (getItem('uid')) {
        return getItem('uid');
      }
    },
    getCurrentUserAccountID: state => {

      if (state.currentUserAccountID) {
        return state.currentUserAccountID;
      }

      if(state.currentUserInfo === null) {
        return null;
      }

      const saveKey = 'aid_' + state.currentUserInfo.id;

      if (getItem(saveKey)) {
        return getItem(saveKey);
      }
    },

    getCurrentUserInfo: state => {
        
        if (state.currentUserInfo) {
          return state.currentUserInfo;
        }
  
        if (getItem('userInfo')) {
          return getItem('userInfo');
        }
      },
      getUserAccountList: state => {
        return state.accountList;
      },
      getAccountListLoading: state => {
        return state.accountListLoading;
      },
      getPreferences: state => {
        return state.prefrences;
      }

  },
})


// Vue.prototype.$baseurl = BASE_URL;

Vue.prototype.$eventHub = new Vue(); // Global event bus

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
<!-- 
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

<template>
	<div id="app">
		<component :is="layout">
			<router-view />
		</component>
	</div>
</template>

<script>

import {getCurrentUserAccountId, isUserLoggedIn} from './services/v1/data_methods';

	export default ({
		computed: {
			// Sets components name based on current route's specified layout, defaults to
			// <layout-default></layout-default> component.
			layout() {
				return "layout-" + ( this.$route.meta.layout || "default" ).toLowerCase() ;
			}
		},
		mounted() {
			//this.$refs.tour.start();
			console.log('mounted app'); 
		},
		data () {
			return {
				// Layout component name.
			}
		},
	})
	
</script>

<style lang="scss">
	/* Import Font Awesome Icons Set */
	$fa-font-path: '~font-awesome/fonts/';
  	@import '~font-awesome/scss/font-awesome.scss';
</style>
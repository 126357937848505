<template>
	
	<!-- Main Sidebar -->
	<a-layout-sider
		collapsible
		class="sider-primary"
		breakpoint="lg"
		collapsed-width="0"
		width="250px"
		:collapsed="sidebarCollapsed"
		@collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
		:trigger="null"
		:class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
		theme="light"
		:style="{ backgroundColor: 'transparent',}">
			<div class="brand"><img src="images/logo-ct-black.png" alt=""> <span>/</span></div>
			<hr>

			<!-- Sidebar Navigation Menu -->
			<a-menu theme="light" mode="inline">
				<a-menu-item>
					<router-link to="/dashboard">
						<span class="icon">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z" fill="#111827"/>
								<path d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z" fill="#111827"/>
								<path d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z" fill="#111827"/>
							</svg>
						</span>
						<span class="label">Dashboard</span>
					</router-link>
				</a-menu-item>
				<a-menu-item id="v-step-0">
					<router-link to="/virtualaccounts">
						<span class="icon">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z" fill="#111827"/>
								<path fill-rule="evenodd" clip-rule="evenodd" d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z" fill="#111827"/>
							</svg>
						</span>
						<span class="label">Virtual Accounts</span>
					</router-link>
				</a-menu-item>
				

				<a-menu-item class="menu-item-header">
					Facebook & Instagram
				</a-menu-item>

				<a-menu-item id="v-step-6">
					<router-link to="/facebookaccounts">
						<span class="icon">
							<i class="fa fa-facebook"></i>
						</span>
						<span class="label">Facebook Accounts</span>
					</router-link>
				</a-menu-item>

				
				
				<a-menu-item class="menu-item-header">
					TikTok
				</a-menu-item>
				<a-menu-item>
					<router-link to="/tiktokaccounts">
						<span class="icon">
							<i class="fa fa-music"></i>
						</span>
						<span class="label">TikTok Accounts</span>
					</router-link>
				</a-menu-item>	
				
			
				
				<a-menu-item class="menu-item-header">
					Multi-Platform Posts
				</a-menu-item>


				<a-menu-item>
					<router-link to="/multiposts">
						<span class="icon">
							<i class="fa fa-bullhorn"></i>
						</span>
						<span class="label">Multi-posts</span>
					</router-link>
				</a-menu-item>



				<a-menu-item class="menu-item-header">
					My Media
				</a-menu-item>


				<a-menu-item>
					<router-link to="/images">
						<span class="icon">
							<i class="fa fa-camera"></i>
						</span>
						<span class="label">Images</span>
					</router-link>
				</a-menu-item>

				<a-menu-item>
					<router-link to="/videos">
						<span class="icon">
							<i class="fa fa-video-camera"></i>
						</span>
						<span class="label">Videos</span>
					</router-link>
				</a-menu-item>


				<a-menu-item class="menu-item-header">
					Docs
				</a-menu-item>


				<a-menu-item>
					<router-link to="/docs">
						<span class="icon">
							<i class="fa fa-book"></i>
						</span>
						<span class="label">API Docs</span>
					</router-link>
				</a-menu-item>	


				<v-tour name="sidebarTour" :steps="steps"></v-tour>

				<v-tour name="sidebarTour2" :steps="stepsFb"></v-tour>

				
				
			</a-menu>
			<!-- / Sidebar Navigation Menu -->

			<!-- Sidebar Footer -->
			<div class="aside-footer">
				<div class="footer-box">
					<span class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z" fill="#111827"/>
							<path d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z" fill="#111827"/>
							<path d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z" fill="#111827"/>
						</svg>
					</span>
					<h6>Need Help?</h6>
					<p>Talk to Us</p>
					<a-button type="primary" href="#/dashboard">
						 info@arucy.com
					</a-button>
				</div>
			</div>
			<!-- / Sidebar Footer -->

		

	</a-layout-sider>
	<!-- / Main Sidebar -->

</template>

<script>

import {fetchDataJSON, fetchPostJSON} from '../../services/v1/services';

import {getCurrentUserAccountId, saveCurrentUserAccountId, saveCurrentUseraccountRole, getCurrentUseraccountId, getCurrentUseraccountRole, getUserInfo, clearUserInfo, isUserLoggedIn} from '../../services/v1/data_methods';

	export default ({
		name: 'sidebar-tour',
		props: {
			// Sidebar collapsed status.
			sidebarCollapsed: {
				type: Boolean,
				default: false,
			},
			
			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary",
			},
			
			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "light",
			},
		},
		async mounted() {
			
			console.log('mounted sidebar'); 

			if(this.$store.getters.isUserLoggedIn) {
				console.log('user is logged in');


				const existingAccounts = this.$store.getters.getUserAccountList;


				if(existingAccounts.length == 0){
					this.$tours['sidebarTour'].start();

					console.log('no accounts found, init tour accounts');

					return;
				}


				const currentUserAccountId = this.$store.getters.getCurrentUserAccountID;


				if(currentUserAccountId != null){
					// console.log('current account id found fb accounts', currentUserAccountId);

					const projectFacebookAccountsRes = await fetchDataJSON(`users/accounts/${currentUserAccountId}` + '/saved_accounts/list?account_type=1&page=1' , true);

            		if(projectFacebookAccountsRes.Ok) {
                			
							this.facebookAccounts = projectFacebookAccountsRes.data.accounts;

							if(this.facebookAccounts.length == 0){
								this.$tours['sidebarTour2'].start()
							}
            		}
					return;
				}
			} else {
				console.log('user is not logged in');
			}
		},
		data() {
			return {
				// sidebarCollapsedModel: this.sidebarCollapsed,

				steps: [
          		{
            		target: '#v-step-0',  // We're using document.querySelector() under the hood
            		content: `Set up Your <strong>Virtual Account</strong>`
          		},
				],

				stepsFb: [
		  		{
					target: '#v-step-6',  // We're using document.querySelector() under the hood
					content: `Set up Your <strong>Facebook Account</strong>`
		  		},
				],
			}
		},
	})

</script>

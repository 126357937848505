const USER_INFO_KEY = 'user_info';

const USER_LOGIN_CHECK_KEY = 'user_loggin_check';

const CURRENT_TOKEN_KEY = 'current_token';



export function relativeTimeFromTs(ts) {
  var now = new Date();
  var fromDate = new Date(ts * 1000);
  var diff = now - fromDate;
  var seconds = Math.floor(diff / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);
  var months = Math.floor(days / 30);
  var years = Math.floor(months / 12);

  if(diff < 0) {

    if (seconds * -1 < 60) return seconds * -1 + " seconds from now";
      else if (minutes * -1 < 60) return minutes * -1 + " minutes from now";
      else if (hours * -1 < 24) return hours * -1 + " hours from now";
      else if (days * -1 < 30) return days * -1 + " days from now";
      else if (months * -1 < 12) return months * -1 + " months from now";
      else return years * -1 + " years from now";
    }


  if (seconds < 60) return seconds + " seconds ago";
  else if (minutes < 60) return minutes + " minutes ago";
  else if (hours < 24) return hours + " hours ago";
  else if (days < 30) return days + " days ago";
  //else if (months < 12) return months + " months ago";
  //else return years + " years ago";

  // return human date if it's more than 30 days
  else if (days > 30) return fromDate.toDateString() + " " + fromDate.toLocaleTimeString();
  else if (months < 12) return fromDate.toDateString() + " " + fromDate.toLocaleTimeString();
  else return fromDate.toDateString() + " " + fromDate.toLocaleTimeString();
}

export function simpleMarkdown(mdText) {

  // first, handle syntax for code-block
  mdText = mdText.replace(/\r\n/g, '\n')
  mdText = mdText.replace(/\n~~~ *(.*?)\n([\s\S]*?)\n~~~/g, '<pre><code title="$1">$2</code></pre>' )
  mdText = mdText.replace(/\n``` *(.*?)\n([\s\S]*?)\n```/g, '<pre><code title="$1">$2</code></pre>' )
  
  // split by "pre>", skip for code-block and process normal text
  var mdHTML = ''
  var mdCode = mdText.split( 'pre>')

  for (var i=0; i<mdCode.length; i++) {
    if ( mdCode[i].substr(-2) == '</' ) {
      mdHTML += '<pre>' + mdCode[i] + 'pre>'
    } else {
      mdHTML += mdCode[i].replace(/(.*)<$/, '$1')
        .replace(/^##### (.*?)\s*#*$/gm, '<h5>$1</h5>')
        .replace(/^#### (.*?)\s*#*$/gm, '<h4 id="$1">$1</h4>')
        .replace(/^### (.*?)\s*#*$/gm, '<h3 id="$1">$1</h3>')
        .replace(/^## (.*?)\s*#*$/gm, '<h2 id="$1">$1</h2>')
        .replace(/^# (.*?)\s*#*$/gm, '<h1 id="$1">$1</h1>')    
        .replace(/^-{3,}|^\_{3,}|^\*{3,}/gm, '<hr/>')    
        .replace(/``(.*?)``/gm, '<code>$1</code>' )
        .replace(/`(.*?)`/gm, '<code>$1</code>' )
        .replace(/^\>> (.*$)/gm, '<blockquote><blockquote>$1</blockquote></blockquote>')
        .replace(/^\> (.*$)/gm, '<blockquote>$1</blockquote>')
        .replace(/<\/blockquote\>\n<blockquote\>/g, '\n<br>' )
        .replace(/<\/blockquote\>\n<br\><blockquote\>/g, '\n<br>' )
        .replace(/!\[(.*?)\]\((.*?) "(.*?)"\)/gm, '<img alt="$1" src="$2" $3 />')
        .replace(/!\[(.*?)\]\((.*?)\)/gm, '<img alt="$1" src="$2" />')
        .replace(/\[(.*?)\]\((.*?) "(.*?)"\)/gm, '<a href="$2" title="$3">$1</a>')
        .replace(/<http(.*?)\>/gm, '<a href="http$1">http$1</a>')
        .replace(/\[(.*?)\]\(\)/gm, '<a href="$1">$1</a>')
        .replace(/\[(.*?)\]\((.*?)\)/gm, '<a href="$2">$1</a>')
        .replace(/^[\*|+|-][ |.](.*)/gm, '<ul><li>$1</li></ul>' ).replace(/<\/ul\>\n<ul\>/g, '\n' )
        .replace(/^\d[ |.](.*)/gm, '<ol><li>$1</li></ol>' ).replace(/<\/ol\>\n<ol\>/g, '\n' )
        .replace(/\*\*\*(.*)\*\*\*/gm, '<b><em>$1</em></b>')
        .replace(/\*\*(.*)\*\*/gm, '<b>$1</b>')
        .replace(/\*([\w \d]*)\*/gm, '<em>$1</em>')
        .replace(/___(.*)___/gm, '<b><em>$1</em></b>')
        .replace(/__(.*)__/gm, '<u>$1</u>')
        .replace(/_([\w \d]*)_/gm, '<em>$1</em>')
        .replace(/~~(.*)~~/gm, '<del>$1</del>')
        .replace(/\^\^(.*)\^\^/gm, '<ins>$1</ins>')
        .replace(/ +\n/g, '\n<br/>')
        .replace(/\n\s*\n/g, '\n<p>\n')
        .replace(/^ {4,10}(.*)/gm, '<pre><code>$1</code></pre>' )
        .replace(/^\t(.*)/gm, '<pre><code>$1</code></pre>' )
        .replace(/<\/code\><\/pre\>\n<pre\><code\>/g, '\n' )
        .replace(/\\([`_\\\*\+\-\.\(\)\[\]\{\}])/gm, '$1' )
    }  
  }
  
  return mdHTML.trim()
}

function clearItems() {
    localStorage.clear();
  }
  
function getItem(key) {
    const existing = localStorage.getItem(key) || null
    try {
      const value = JSON.parse(existing);
      return value;
    } catch (e) {
      // // outdisp('err data:' + e);
      return existing;
    }
  }

  function getItemSession(key) {
    const existing = sessionStorage.getItem(key) || null
    try {
      const value = JSON.parse(existing);
      return value;
    } catch (e) {
      // // outdisp('err data:' + e);
      return existing;
    }
  }

function saveItem(key, val) {
    if (typeof val === 'string') {
        localStorage[key] = val
    }
    else{
        localStorage[key] = JSON.stringify(val)
    }
  }


  function saveItemSession(key, val) {
    if (typeof val === 'string') {
        sessionStorage[key] = val
    }
    else{
        sessionStorage[key] = JSON.stringify(val)
    }
  }


export function isUserLoggedIn() {
  try {

      return Object.keys(getItem(USER_INFO_KEY)).length > 0;

  } catch (e) {
      return false;
  }
}
  
export function isFirstLogIn(userid) {
    return getItem(USER_LOGIN_CHECK_KEY + '_' + userid) === null;
}

export function currentToken() {
    return getItem(CURRENT_TOKEN_KEY);
}



export function saveCurrentToken(token) {
    saveItem(CURRENT_TOKEN_KEY, token);
}


export function saveCurrentUserAccountId(userid, projectid) {
    saveItem(userid + '_prid', projectid);
}

export function saveCurrentUserProjectRole(userid, projectrole) {
    saveItem(userid + '_role', projectrole);
}

export function getCurrentUserAccountId(userid) {
    return getItem(userid + '_prid');
}

export function getCurrentUserProjectRole(userid) {
    return getItem(userid + '_role');
}


export function saveisFirstLogIn(userid) {
    saveItem(USER_LOGIN_CHECK_KEY + '_' + userid, userid);
}

export function saveUserInfo(userInfo) {
    saveItem(USER_INFO_KEY, userInfo);
}

export function getUserInfo() {
    return getItem(USER_INFO_KEY);
}

export function clearUserInfo() {
    // localStorage.removeItem(USER_INFO_KEY);
    // localStorage.removeItem(CURRENT_TOKEN_KEY);

    localStorage.clear();

    sessionStorage.clear();

    window.location.reload();
}
